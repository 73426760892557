import React from "react";

const Page404 = () => {
  return (
    <div className='page-404'>
      <div className='inner px-md'>
        <h1>Page Not Found</h1>
        <p>The page requested couldn't be found</p>
        <a href='/'>Back home</a>
      </div>
    </div>
  );
};

export default Page404;
